import React, { useEffect, useState } from "react";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxCross1 } from "react-icons/rx";
import { BsArrowLeft } from "react-icons/bs";
import "./View.scss";
import { useNavigate, useParams } from "react-router-dom";
import Excel from "../../../assets/img/excel.png";
import Word from "../../../assets/img/word.png";
import Pdf from "../../../assets/img/pdf.png";
import AddEditSops from "../AddForm/AddEditSops";
import DeleteModal from "../../Model/DeleteModal";
import { toast } from "react-toastify";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { Decryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import FileDisplay from "../../FileInput/FileDisplay";

const SopsView = ({ companyId, locationId }) => {
  const params = useParams();
  const { id } = params || {};
  const [sops, setSops] = useState([]);
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);
  const [open, setOpen] = React.useState(false);
  const [DeleteSops, setDeleteSops] = useState(false);
  const [deleteImg, setDeleteImg] = useState("");
  const [loading, setLoading] = useState(false);
  const decryptedId = Decryption(id);
  const { company_id, location_id } = useFilter();

  const fetchSops = async () => {
    setLoading(true);
    try {
      await httpService.get(`sops/get_sop/${decryptedId}`).then((response) => {
        // console.log(response.data[0]);
        setSops(response?.data[0]);
        setDeleteImg("");
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSops();
  }, [params?.id, showLoginPopup]);

  const handleOpen = () => {
    setOpen(true);
    fetchSops();
  };

  const handleClose = () => {
    setOpen(false);
    setDeleteImg("");
    setDeleteSops(false);
    fetchSops();
  };

  const HandleDeleteSops = async () => {
    if (deleteImg !== "" && deleteImg !== undefined) {
      await handleDeleteImg(deleteImg);
    } else {
      try {
        let response = await httpService.delete(
          `${"sops/delete_sop"}/${decryptedId}`
        );
        navigate("/sops");
        console.log(response);
        if (response) {
          toast.success("SOPs Deleted successfully", {
            position: "top-right",
          });
          setDeleteSops(false);
          fetchSops();
        }
      } catch (error) {
        setDeleteSops(false);
        console.error("Error deleting SOPs:", error);
      }
    }
  };

  const handleDeleteImg = async (imgId) => {
    const newArray = [...sops?.attachment]; // Create a copy of the attached array
    newArray.splice(imgId, 1);

    const formData = new FormData();
    for (const key in sops) {
      if (
        key !== "tasks" &&
        key !== "locations" &&
        key !== "milestones" &&
        key !== "activities"
      ) {
        if (key === "attachment") {
          for (let i = 0; i < newArray.length; i++) {
            formData.append(`existFile[${i}]`, newArray[i]);
          }
        } else if (key === "milestone_id" && sops[key]) {
          for (let i = 0; i < sops[key].length; i++) {
            formData.append(`milestone_id[${i}]`, sops[key][i]);
          }
        } else if (key === "location_id" && sops[key]) {
          for (let i = 0; i < sops[key].length; i++) {
            formData.append(`location_id[${i}]`, sops[key][i]);
          }
        }
        if (key === "activity_id" && sops[key]) {
          for (let i = 0; i < sops[key].length; i++) {
            formData.append(`activity_id[${i}]`, sops[key][i]);
          }
        } else {
          if (
            sops[key] !== null &&
            key !== "location_id" &&
            key !== "milestone_id" &&
            key !== "activity_id" &&
            key !== "task_id"
          ) {
            const value = key === "sort_order" ? sops[key] || "" : sops[key];
            formData.append(key, value);
          }
        }
      } else {
        sops[key]?.map((item, index) => {
          if (key === "tasks") {
            formData.append(`task_id[${index}]`, item.id);
          }
        });
      }
    }
    try {
      let response = await httpService.put(
        `${"sops/edit_sop"}/${decryptedId}`,
        formData
      );
      if (response.message) {
        setDeleteSops(false);
        fetchSops();
        toast.success("File Deleted successfully", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Error adding Sops:", error);
      setDeleteSops(false);
    }
  };

  return (
    <>
      {loading && <FullScreenLoader />}

      <div className="sops-view">
        <div className="page-title">
          <div className="title-wrap">
            <h1>
              <span className="back">
                <BsArrowLeft onClick={() => navigate("/sops")} />
              </span>
              SOPs Details - [{sops.name ? sops.name : "--"}]
            </h1>
          </div>
          <div className="button-wrap view-responsive">
            <button
              type="submit"
              className="btn btn-edit"
              onClick={() => handleOpen()}
            >
              <CiEdit /> Edit{" "}
            </button>
            <button
              type="submit"
              className="btn btn-delete"
              onClick={() => {
                setDeleteSops(true);
              }}
            >
              <GoTrash /> Delete{" "}
            </button>
          </div>
        </div>
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>SOPs Name </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p>{sops.name ? sops.name : "--"}</p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Reff Doc. Version(s)</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>{sops?.version ? sops?.version : "--"}</p>
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Company</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    <p>{sops?.company_name ? sops?.company_name : "--"}</p>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Location</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <div className="milestone-list-wrap">
                    {sops?.locations?.map((item, index) => (
                      <>
                        {item?.name !== null ? (
                          <span key={index}>{item.name}</span>
                        ) : (
                          <span key={index}>--</span>
                        )}
                      </>
                    ))}
                  </div>
                </div>

                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Milestone</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {sops?.milestones?.map((item, index) => (
                      <>
                        {item?.name !== null ? (
                          <span key={index}>{item.name}</span>
                        ) : (
                          <span key={index}>--</span>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Activity</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {sops?.activities?.map((item, index) => (
                      <>
                        {item?.name !== null ? (
                          <span key={index}>{item.name}</span>
                        ) : (
                          <span key={index}>--</span>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Work Task</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    <span>
                      {sops?.maintenance_name ? sops?.maintenance_name : "--"}
                    </span>
                  </div>
                </div>
                <div className="col-md-2 mb-md-4">
                  <div className="d-flex justify-content-md-between">
                    <label>Tasks</label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10 mb-3">
                  <div className="milestone-list-wrap">
                    {sops?.tasks?.map((tasks, index) => (
                      // Use key prop to provide a unique key for each child
                      <span key={index}>{tasks.name}</span>
                    ))}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Description </label>
                    <span>:</span>
                  </div>
                </div>
                <div className="col-md-10">
                  <p className="text-justify">
                    {sops.description ? sops.description : "--"}
                  </p>
                </div>
                <div className="col-md-2">
                  <div className="d-flex justify-content-md-between">
                    <label>Files </label>
                    <span>:</span>
                  </div>
                </div>
                <FileDisplay
                  attached={sops?.attachment}
                  handleDelete={() => setDeleteSops(true)}
                  setDeleteImg={setDeleteImg}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {open && (
        <AddEditSops
          open={open}
          handleClose={() => handleClose()}
          clickedsops={decryptedId}
          isAdd={false}
          fetchSops={() => fetchSops()}
          companyId={company_id}
          locationId={location_id}
        />
      )}

      <DeleteModal
        open={DeleteSops}
        HandleClose={() => handleClose()}
        id={deleteImg}
        HandleDelete={() => HandleDeleteSops()}
      />
    </>
  );
};

export default SopsView;
