import React, { useEffect, useState } from "react";
import { DataGrid, GridMoreVertIcon } from "@mui/x-data-grid";
import { CiEdit } from "react-icons/ci";
import { GoTrash } from "react-icons/go";
import { RxPlus } from "react-icons/rx";
import "./Sops.scss";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Link,
  MenuItem,
  Popover,
  gridClasses,
  styled,
} from "@mui/material";
import { HiOutlineEye } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AddEditSops from "../../../components/Sops/AddForm/AddEditSops";
import DeleteModal from "../../../components/Model/DeleteModal";
import { HttpService } from "../../../service/HttpService";
import FullScreenLoader from "../../../components/Loader/FullScreenLoader";
import { useAuth } from "../../../context/AuthProvider";
import { Encryption } from "../../../Mixin/EncryptDecryptId";
import { useFilter } from "../../../context/FilterProvider";
import { handleDownloadSopsExcel } from "../DownloadSops";
import { SiMicrosoftexcel } from "react-icons/si";

const Sops = () => {
  const navigate = useNavigate();
  const { setShowLoginPopup, showLoginPopup } = useAuth();
  const httpService = new HttpService(setShowLoginPopup);

  const [open, setOpen] = React.useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [sops, setSops] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [clickedsops, setClickedsops] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [DeleteSops, setDeleteSops] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [dataId, setDataId] = useState();
  const [loading, setLoading] = useState(false);
  const { company_id, location_id } = useFilter();
  const [expanded, setExpanded] = useState(false);

  const [pageSize, setPageSize] = useState(15);
  const [rowCount, setRowCount] = useState("");
  const [page, setPage] = useState(0);
  const [columnName, setColumnName] = useState("");
  const [sortOrder, setsortOrder] = useState("");
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [selectedId, setSelectedId] = useState([]);
  const [allIds, setAllIds] = useState(0);

  function ExpandableCell({ value }) {
    const displayValue = expanded ? value : value?.slice(0, 170);

    return (
      <div>
        {displayValue}&nbsp;
        {value.length > 170 && (
          <Link component="button" onClick={() => setExpanded(!expanded)}>
            {expanded ? "view less" : "view more"}
          </Link>
        )}
      </div>
    );
  }

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const fetchSops = () => {
    setLoading(true);
    httpService
      .get(
        `sops/get_sops?page=${
          page + 1
        }&pageSize=${pageSize}&search=${searchData}&sortBy=${columnName}&sortOrder=${sortOrder}`
      )
      .then((response) => {
        setSops(response?.data?.data);
        // setSearchData(response?.data);
        setRowCount(response?.data?.pagination?.totalRecords);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchSops();
  }, [
    company_id,
    location_id,
    showLoginPopup,
    page,
    pageSize,
    searchData,
    columnName,
    sortOrder,
  ]);

  const handleOpen = () => {
    setOpen(true);
    setIsAdd(true);
    setClickedsops("");
  };

  const handleClose = () => {
    setOpen(false);
    setIsAdd(false);
    setClickedsops("");
    setOpenDelete(false);
    fetchSops();
  };

  const viewPage = (id) => {
    const urlEncodedId = Encryption(id);
    // Navigate to the encrypted URL
    navigate(`view/${urlEncodedId}`);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const CustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <Box sx={{ mt: 1 }}>No Data Found</Box>
    </StyledGridOverlay>
  );

  // const searchSops = (searchTerm) => {
  //   const searchTermLowerCase = searchTerm.toLowerCase();
  //   const results =
  //     sops?.length > 0 &&
  //     sops?.filter((item) => {
  //       for (let key in item) {
  //         if (
  //           typeof item[key] === "string" &&
  //           item[key].toLowerCase().includes(searchTermLowerCase)
  //         ) {
  //           return true;
  //         }
  //       }
  //       return false;
  //     });
  //   if (results) {
  //     setSearchData(results);
  //   }
  // };

  const HandleDeleteSops = async () => {
    if (DeleteSops) {
      try {
        const response = await httpService.delete(
          `${"sops/delete_sop"}/${DeleteSops}`
        );
        if (response) {
          fetchSops();
          navigate("/sops");
          toast.success("SOPs Deleted successfully", {
            position: "top-right",
          });
        } else {
          throw response;
        }
        setOpenDelete(false);
        fetchSops();
      } catch (error) {
        setOpenDelete(false);
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    }
  };

  const other = {
    showCellVerticalBorder: true,
    showColumnVerticalBorder: true,
  };

  const onDownloadClick = async () => {
    try {
      await handleDownloadSopsExcel(setShowLoginPopup);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };

  const handleHeaderCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      const allIds = sops.map((row) => row.id); // Get all the IDs from the data
      setSelectedId(allIds); // Select all IDs
      console.log("Total selected IDs: ", allIds.length);
      setAllIds(allIds.length);
    } else {
      setSelectedId([]); // Deselect all
      console.log("Total selected IDs: 0");
    }

    setIsAllSelected(isChecked);
  };
  const isIdSelected = (id) => {
    return selectedId.includes(id);
  };

  const toggleId = (id) => {
    setSelectedId((prev) => {
      if (prev.includes(id)) {
        const updatedSelectedIds = prev.filter((item) => item !== id);
        if (updatedSelectedIds.length < allIds) {
          setIsAllSelected(false);
        }
        return updatedSelectedIds;
      } else {
        const updatedSelectedIds = [...prev, id];
        if (updatedSelectedIds.length === allIds) {
          setIsAllSelected(true);
        }
        return updatedSelectedIds;
      }
    });
  };

  const deleteSelectedSops = async () => {
    if (selectedId?.length > 0) {
      try {
        const response = await httpService.post("sops/delete_all_sop", { sopIds: selectedId });
        if (response?.success) {
          fetchSops();
          const messages = response?.data?.combinedMessage ? response?.data?.combinedMessage : response?.data?.message;
          toast.success(messages, {
            position: "top-right",
          });
        } else {
          throw response;
        }
        fetchSops();
      } catch (error) {
        toast.error(error?.response?.data?.error, {
          position: "top-right",
        });
      }
    } else {
      toast.error("Please select atleast one Sops", {
        position: "top-right",
      });
    }
  };

  return (
    <>
      {/* <div>
        <h1 style={{ color: "blue", fontFamily: "wf_title" }}>Coming soon!!</h1>
      </div> */}
      {loading && <FullScreenLoader />}

      <div className="sops">
        <div className="page-title">
          <div className="title-wrap">
            <h1>SOPs</h1>
          </div>
          <div className="button-wrap">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleOpen}
            >
              <RxPlus /> Add SOPs{" "}
            </button>
          </div>
        </div>
        <div className="table-filter-wrap d-flex justify-content-between">
          <Button className="btn btn-delete" onClick={()=>deleteSelectedSops()} >
            Delete
          </Button>
          <div className="d-flex flex-row">
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDownloadClick()}
              startIcon={<SiMicrosoftexcel />}
              className="me-3"
            >
              Download
            </Button>
            <div className="header_search-wrap">
              <div className="header_search_bar common_content">
                <div className="input-group input_group">
                  <button className="btn search-btn search_btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 21 21"
                      fill="none"
                    >
                      <circle
                        cx="9.98856"
                        cy="9.98856"
                        r="8.98856"
                        stroke="#495057"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.2402 16.7071L19.7643 20.222"
                        stroke="#495057"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <input
                    type="search"
                    className="form-control form_control"
                    placeholder="Search..."
                    aria-label="Search"
                    onChange={(e) => {
                      const value = e.target.value;
                      setSearchData(value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            height: `${sops?.length > 0 ? "" : "200px"}`,
            width: "100%",
          }}
        >
          <DataGrid
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={
              sops?.length > 0 &&
              sops?.map((sops, index) => ({
                ...sops,
                id: sops.id,
                sequenceNumber: index + 1,
              }))
            }
            getRowHeight={() => "auto"}
            sx={{
              [`& .${gridClasses.cell}`]: {
                py: 0.5,
              },
            }}
            columns={[
              {
                field: "select_All",
                headerName: "Select All",
                minWidth: 150,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => {
                  console.log("Id : ",params.row.id)
                  return (
                    <Checkbox
                      value="Checked"
                      checked={isAllSelected ? true : isIdSelected(params.row.id) ? true : false}
                      onChange={() => toggleId(params.row.id)}
                    />
                  );
                },
                headerCheckboxSelection: true,
                renderHeader: (params) => {
                  return (
                    <>
                      <Checkbox checked={isAllSelected} onChange={(e) => handleHeaderCheckboxChange(e)} />
                      <strong>Select All</strong>
                    </>
                  );
                },
              },

              {
                field: "name",
                headerName: "SOPs Name",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <div className="employee-name-weap">
                      <span>{params.row.name}</span>
                    </div>
                  );
                },
              },
              {
                field: "activity_name",
                headerName: "Activity",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  const activities = params?.row?.activities || []; // Set activities to an empty array if undefined
                  const activityNames = activities
                    .map((activity) => activity.name || "--") // Use 'name' instead of 'activity_name'
                    .join(", ");
                  return <span>{activityNames || "--"}</span>;
                },
              },
              {
                field: "milestone_name",
                headerName: "Milestone",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  const milestones = params?.row?.milestones || []; // Set activities to an empty array if undefined
                  const milestoneNames = milestones
                    .map((milestone) => milestone.name || "--") // Use 'name' instead of 'activity_name'
                    .join(", ");
                  return <span>{milestoneNames || "--"}</span>;
                },
              },
              {
                field: "company_name",
                headerName: "Company",
                width: 200,
                renderCell: (params) => {
                  return (
                    <div className="employee-name-weap">
                      <span>{params.row.company_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "location_name",
                headerName: "Location",
                width: 200,
                renderCell: (params) => {
                  const locations = params?.row?.locations || []; // Set activities to an empty array if undefined
                  const locationNames = locations
                    .map((location) => location.name || "--") // Use 'name' instead of 'activity_name'
                    .join(", ");
                  return <span>{locationNames || "--"}</span>;
                },
              },
              {
                field: "maintenance_name",
                headerName: "Work Task",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  return (
                    <div className="employee-name-weap">
                      <span>{params.row.maintenance_name || "--"}</span>
                    </div>
                  );
                },
              },
              {
                field: "tasks",
                headerName: "Tasks",
                flex: 1,
                minWidth: 200,
                renderCell: (params) => {
                  const tasks = params.row.tasks;

                  const t_name = tasks
                    ?.map((task) => task.name || "")
                    ?.join(", ");
                  return (
                    <div className="employee-name-weap">
                      <span>
                        {t_name ? <ExpandableCell value={t_name} /> : "--"}
                      </span>
                    </div>
                  );
                },
                // renderCell: (params) => <ExpandableCell {...params} />,
              },
              {
                field: "action",
                headerName: "Actions",
                headerAlign: "center",
                align: "center",
                width: 150,
                renderCell: (params) => (
                  <>
                    <div className="action_icon-weap">
                      <IconButton
                        onClick={(e) => {
                          handleMenuClick(e), setDataId(params.row.id);
                        }}
                      >
                        <GridMoreVertIcon />
                      </IconButton>
                    </div>
                    {anchorEl && dataId === params?.row?.id && (
                      <Popover
                        className="popup-menu"
                        key={dataId}
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={handleCloseMenu}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            // console.log(params.row.id);
                            viewPage(dataId);
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">View</div>
                          <HiOutlineEye style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setIsAdd(false);
                            setClickedsops(dataId);
                            setOpen(true);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Edit</div>{" "}
                          <CiEdit style={{ cursor: "pointer" }} />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setOpenDelete(true);
                            setDeleteSops(dataId);
                            handleCloseMenu();
                          }}
                          className="menu-list-items"
                        >
                          <div className="menu-list-items-text">Delete</div>{" "}
                          <GoTrash style={{ cursor: "pointer" }} />
                        </MenuItem>
                      </Popover>
                    )}
                  </>
                ),
              },
            ]}
            initialState={{
              pagination: {
                rowCount: -1,
              },
            }}
            onSortModelChange={(e) => {
              if (e && e.length > 0) {
                setsortOrder(e[0].sort);
                setColumnName(e[0].field);
              } else {
                setsortOrder("");
                setColumnName("");
              }
            }}
            paginationMode="server"
            sortingMode="server"
            rowCount={rowCount}
            pageSizeOptions={[15, 50, 100]}
            paginationModel={{ pageSize: pageSize, page: page }}
            onPaginationModelChange={(event) => {
              setPage(event?.page);
              setPageSize(event?.pageSize);
            }}
            checkboxSelection={false}
            rowSelection={false}
            autoHeight
            {...other}
          />
        </div>
      </div>

      {open && (
        <AddEditSops
          open={open}
          isAdd={isAdd}
          clickedsops={clickedsops}
          handleClose={() => handleClose()}
          fetchSops={() => fetchSops()}
          companyId={company_id}
          locationId={location_id}
        />
      )}
      <DeleteModal
        open={openDelete}
        HandleClose={() => handleClose()}
        HandleDelete={() => {
          HandleDeleteSops(), setClickedsops("");
        }}
      />
    </>
  );
};

export default Sops;
